import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, uniqBy } from 'lodash';
import classnames from 'classnames';
import Card from 'reactstrap/lib/Card';
import Button from 'reactstrap/lib/Button';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildEvTestedDataPath,
  FeatureSpecsEntities,
  FeatureSpecsPaths,
  VehicleEntities,
  VehicleModel,
} from 'client/data/models/vehicle-v2';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
  InventoryTrimSummaryEntities,
} from 'client/data/models/inventory-core-configurations';
import { PageModel } from 'client/data/models/page';
import { getTrimsWithLabels } from 'site-modules/shared/utils/core-page/trims-cta-utils';
import { CorePageParams, getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getFeaturesSpecsUrlStandard, transformMmsyAndParams } from 'site-modules/shared/utils/core-link-constructor';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { EV_TESTED_DATA_START_DATE } from 'site-modules/shared/constants/range-and-cost';
import { Link } from 'site-modules/shared/components/link/link';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { SpeculationRule } from 'site-modules/shared/components/speculation-rule/speculation-rule';
import { SubmodelTrimSelect, ANCHOR_ID } from './submodel-trim-select';
import { FeaturesList } from './features-list';

import './features-highlights.scss';

function FeaturesHighlightsUI({
  className,
  stylesFeatures,
  trims,
  sharedTrimName,
  setModelValue,
  stylesEvTestedData,
  params,
  vehicle,
  isMobile,
}) {
  const [styleId, setStyleId] = useState(null);
  const containerRef = useRef();

  const onTrimChange = useCallback(
    ({ trimName }) => {
      setModelValue('sharedValues.trimName', PageModel, trimName);
    },
    [setModelValue]
  );

  const onStyleChange = useCallback(({ id }) => {
    setStyleId(id);
  }, []);

  useEffect(() => {
    setStyleId(null);
  }, [sharedTrimName]);

  if ((!trims && !params.isUsed) || !get(stylesFeatures, 'length')) {
    return null;
  }

  const {
    make: { slug: makeSlug, name: makeName },
    model: { slug: modelSlug, name: modelName },
    submodels: { slug: submodelSlug },
    year,
  } = vehicle;

  const selectedSubmodelId = `${year}|${submodelSlug}`;
  // build trims from stylesFeatures for used vehicles
  const currentTrims =
    trims ||
    uniqBy(stylesFeatures, 'trim').map(trim => ({
      trimName: trim.trim,
      styleId: trim.id,
    }));
  const currentTrim =
    (currentTrims && currentTrims.find(({ trimName }) => trimName === sharedTrimName)) || get(currentTrims, '[0]');
  const currentStyleId = styleId || currentTrim.styleId;
  const styleFeatures = stylesFeatures.find(({ id }) => id === currentStyleId);
  const styleEvTestedData =
    stylesEvTestedData && stylesEvTestedData.find(({ styleId: evStyleId }) => +evStyleId === currentStyleId);

  const featuresSpecsUrl = getFeaturesSpecsUrlStandard(
    transformMmsyAndParams({ makeModelSubmodelYear: vehicle, params }),
    currentStyleId
  );

  return (
    <Card
      innerRef={containerRef}
      className={classnames('pos-r d-block features-highlights px-1 pt-1 pb-1_5', className)}
      data-tracking-parent="features-and-specs"
    >
      <ScrollElement id={ANCHOR_ID} style={{ position: 'absolute', top: `${SCROLL_OFFSET}px` }} />
      <h2 className="heading-3 mb-0_5">
        {params.isUsed ? `${year} ${modelName} Highlights` : `${year} ${makeName} ${modelName} Features & Specs`}
      </h2>
      <SubmodelTrimSelect
        params={params}
        className="mb-1"
        selectedSubmodelId={selectedSubmodelId}
        currentTrim={currentTrim}
        trims={currentTrims}
        onTrimChange={onTrimChange}
      />
      <hr className="mt-0 mb-1" />
      <FeaturesList
        vehicleParams={{ makeSlug, modelSlug, year }}
        className="mb-2"
        stylesFeatures={stylesFeatures}
        styleId={currentStyleId}
        styleEvTestedData={styleEvTestedData}
        onStyleChange={onStyleChange}
        isMobile={isMobile}
        isUsed={params.isUsed}
        showTaxRebates={
          !!(get(styleFeatures, 'styleAttributes.electric') || get(styleFeatures, 'styleAttributes.pluginElectric'))
        }
      />
      <div className="text-center">
        <Button
          tag={Link}
          to={featuresSpecsUrl}
          size="sm"
          color="outline-primary-b"
          className={classnames('size-16 text-transform-none', { 'w-100': isMobile })}
          data-tracking-id="view_features_specs"
        >
          See all features & specs
        </Button>
      </div>
      {!params.isUsed && <SpeculationRule observeRef={containerRef} urls={[featuresSpecsUrl]} />}
    </Card>
  );
}

FeaturesHighlightsUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  setModelValue: PropTypes.func.isRequired,
  className: PropTypes.string,
  stylesFeatures: PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity),
  trims: InventoryTrimSummaryEntities.TrimSummaries,
  sharedTrimName: PropTypes.string,
  stylesEvTestedData: PropTypes.arrayOf(VehicleEntities.StyleEvTestedData),
  isMobile: PropTypes.bool,
};

FeaturesHighlightsUI.defaultProps = {
  className: null,
  stylesFeatures: null,
  trims: null,
  sharedTrimName: null,
  stylesEvTestedData: null,
  isMobile: false,
};

const FeaturesHighlightsWrapper = connectToModel(FeaturesHighlightsUI, {
  stylesEvTestedData: bindToPath(
    ({ stylesFeatures, vehicle }) =>
      !!stylesFeatures &&
      Object.keys(get(vehicle, 'edmundsTypeCategories', {})).includes('electric') &&
      vehicle.year >= EV_TESTED_DATA_START_DATE &&
      buildEvTestedDataPath(stylesFeatures.map(({ id }) => id)),
    VehicleModel
  ),
});

export const FeaturesHighlights = connectToModel(FeaturesHighlightsWrapper, {
  sharedTrimName: bindToPath('sharedValues.trimName', PageModel),
  stylesFeatures: bindToPath(
    ({ vehicle }) => FeatureSpecsPaths.buildPartialFeaturesPath(getParamsFromVehicle(vehicle)),
    VehicleModel
  ),
  trims: bindToPath(
    ({ vehicle, params }) => !params.isUsed && buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
    InventoryCoreConfigurationsModel,
    getTrimsWithLabels
  ),
});
